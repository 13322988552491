import {Controller} from "stimulus"

import Rails from '@rails/ujs';

export default class extends Controller {
    static targets = ["form"]

    connect() {
        console.log("Responder controller connected...")
    }

    success() {
        this.setStatus("Saved")
        Rails.fire(this.formTarget, 'submit')
    }

    setStatus(message) {
        console.log(message)
    }
}
