// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

console.log("application.js is loaded")

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

window.jQuery = $;
window.$ = $;

import 'bootstrap'
import '@fortawesome/fontawesome-pro/js/all.js'
import 'synapse_ui/app/javascript/packs/application'
import 'stylesheets/application'

// Configure Stimulus
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
console.log("Local controllers:", definitionsFromContext(context))

const synapse_ui_context = require.context('synapse_ui/app/javascript/packs/controllers', true, /\.js$/)
application.load(definitionsFromContext(synapse_ui_context))
console.log("Synapse UI controllers:", definitionsFromContext(synapse_ui_context))

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", () => {
  $("[data-toggle='tooltip']").tooltip()
})
